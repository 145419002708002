.ReactCrop__crop-selection {
    box-shadow: 0 0 0 9999em rgb(0 0 0 / 40%);
    border: 0;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='8' stroke-dasharray='2' stroke-dashoffset='3' stroke-linecap='butt'/%3e%3c/svg%3e");
}

.ReactCrop__child-wrapper > img, .ReactCrop__child-wrapper > video {
    /* Allow image being cropped to scroll */
    touch-action: unset;
}
