@tailwind base;
@layer base {
  html {
    @apply font-myriad antialiased;
  }
}
@tailwind components;
@tailwind utilities;

@layer components {
  .c-button {
    @apply relative inline-flex items-center w-full py-3 px-14 font-myriad font-bold disabled:cursor-not-allowed;
  }

  .c-button--primary {
    @apply bg-primary-purple border border-transparent justify-center rounded-pill text-white text-lg disabled:bg-secondary-dark-grey;
  }

  .c-button-chevron--primary {
    @apply absolute top-1/2 right-[27px] -translate-y-2/4;
  }

  .c-button--white {
    @apply bg-white py-5 px-[18px] rounded-[14px] flex items-center text-secondary-dark-grey text-left shadow-[0_1px_12px_rgba(0,0,0,0.12)];
  }

  .c-button--small {
    @apply bg-white p-3 rounded-[14px] flex items-center text-secondary-dark-grey text-left shadow-[0_1px_12px_rgba(0,0,0,0.12)];
  }

  .c-button-chevron--white {
    @apply absolute top-1/2 right-[18px] -translate-y-2/4;
  }

  .c-button--secondary {
    @apply border border-primary-purple text-lg font-normal rounded-pill justify-center bg-white text-primary-purple disabled:border-secondary-dark-grey;
  }

  .c-link {
    @apply font-myriad text-lg font-bold text-primary-purple;
  }
}

@layer utilities {
  .text-shadow-heavy {
    text-shadow: 0 2px 12px rgb(0 0 0 / 24%);
  }

  .box-shadow-heavy {
    box-shadow: 0 2px 12px rgb(0 0 0 / 24%);
  }

  .box-shadow-card {
    box-shadow: 0 20px 52px rgb(0 0 0 / 12%);
  }

  .box-shadow-light {
    box-shadow: 0 1px 12px rgb(0 0 0 / 12%);
  }

  /* https://github.com/pascalduez/postcss-scrollbar#examples */
  .no-scrollbars::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .no-scrollbars {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .rounded-inherit {
    border-radius: inherit;
  }

  .hyphens-auto {
    /* For this to work you need to set lang="en" on the element */
    hyphens: auto;
  }
}

@font-face {
  font-family: "Myriad Pro";
  src: url("../../public/font/MyriadPro-Bold.woff2") format("woff2"),
    url("../../public/font/MyriadPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Myriad Pro";
  src: url("../../public/font/MyriadPro-Regular.woff2") format("woff2"),
    url("../../public/font/MyriadPro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Myriad Pro";
  src: url("../../public/font/MyriadPro-Light.woff2") format("woff2"),
    url("../../public/font/MyriadPro-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Myriad Pro";
  src: url("../../public/font/MyriadPro-Semibold.woff2") format("woff2"),
    url("../../public/font/MyriadPro-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@keyframes unlock-wiggle {
  from,
  45%,
  to {
    transform: rotate(0deg) translateX(0);
  }

  15% {
    transform: rotate(-8deg) translateY(4px);
  }

  30% {
    transform: rotate(8deg) translateY(10px);
  }
}
